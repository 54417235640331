import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiService } from 'src/app/shared/services/api/api.service';
import { AppStateService } from 'src/app/shared/services/app-state/app-state.service';

export interface Facility {
  id: string;
  organisationId: string;
  name: string;
  displayName: string;
  legacyFacilityId?: string;
  country: string;
}

@Injectable({
  providedIn: 'root'
})
export class FacilityService {

  constructor(
    private apiService: ApiService,
    private appState: AppStateService,
  ) { }


  getFacility(id: string): Observable<Facility> {
    const statement = `
      query getFacility($id: ID!) {
        getFacility(id: $id) {
          id
          name
          displayName
          country
          legacyFacilityId
        }
      }
    `;
    return this.apiService
      .graphql<Facility>({ statement, variables: { id }, type: 'getFacility'})
      .pipe(
        tap(facility => {
          this.appState.setState('currentFacility', facility);
        })
      )

  }
}
