import { Route, Routes, UrlSegment } from '@angular/router';

import { SingleLayoutComponent } from './shared/components/single-layout/single-layout.component';
import { ExpiredComponent } from './modules/auth/containers/expired/expired.component';
import { LoginComponent } from './modules/auth/containers/login/login.component';
import { LogoutComponent } from './modules/auth/containers/logout/logout.component';

import { TokenLoginGuard } from './modules/auth/guards/tokenLogin/tokenLogin.guard';
import { AuthGuard } from './modules/auth/guards/auth/auth.guard';

import { contentResolver } from './shared/resolvers/content/content.resolver';
import { residentResolver } from './shared/resolvers/resident/resident.resolver';
import { facilityResolver } from './shared/resolvers/facility/facility.resolver';

import { APOLLO_USER_GROUPS } from './shared/models/user';
import { LogoutGuard } from './modules/auth/guards/logout/logout.guard';
import { AccessGuard } from './modules/auth/guards/access/access.guard';
import { groups } from './modules/wizard/config/groups';
import { wizardQueryResolver } from './modules/wizard/resolvers/query.resolver';
import { WizardGroup } from '@resparke/modules';
import { residentPhotosResolver } from './shared/resolvers/residentPhotos/residentPhotos.resolver';

export const APP_ROUTES: Routes = [
  {
    path: '',
    component: SingleLayoutComponent,
    children: [
      {
        path: 'login',
        canActivate: [TokenLoginGuard],
        component: LoginComponent,
      },
      {
        path: 'logout',
        resolve: {
          content: contentResolver,
        },
        data: {
          loadContent: ['family-logout:heading', 'family-logout:body'],
        },
        component: LogoutComponent,
      },
      {
        path: 'expired',
        resolve: {
          content: contentResolver,
        },
        data: {
          loadContent: ['magic-link-expired:heading', 'magic-link-expired:body'],
        },
        component: ExpiredComponent,
      },
    ]
  },
  {
    path: 'do-logout',
    // DUMMY COMPONENT. LOGOUT HAPPENS IN GUARD
    component: LogoutComponent,
    canActivate: [
      LogoutGuard
    ],
  },
  {
    path: 'page-not-found',
    component: SingleLayoutComponent,
    children: [
      {
        path: '',
        loadComponent: () => import('./shared/containers/page-not-found/page-not-found.component').then(m => m.PageNotFoundComponent)
      }
    ]
  },
  {
    path: 'lifestory',
    loadComponent: () => import('./shared/components/wizard-layout/wizard-layout.component').then(m => m.WizardLayoutComponent),
    data: {
      groups: [APOLLO_USER_GROUPS.FAMILY],
      config: {
        sidebar: {
          content: 'wiz-home-helper:content',
        },
        form: {
          content: {
            preTitle: 'wiz-home:pretitle',
            title: 'wiz-home:title',
            introduction: 'wiz-home:introduction',
            onboardingIntroduction: 'wiz-home:onboarding-introduction',
          },
        },
      },
      loadContent: ['wiz-home-helper:content', 'wiz-home:introduction', 'wiz-home:onboarding-introduction', 'wiz-home:title', 'wiz-home:pretitle'],
    },
    canActivate: [AuthGuard, AccessGuard],
    canActivateChild: [AccessGuard],
    resolve: {
      facility: facilityResolver,
      resident: residentResolver,
      content: contentResolver,
      residentPhotos: residentPhotosResolver,
    },
    children: [
      {
        path: '',
        loadComponent: () => import('./modules/lifestory/containers/main/main.component').then(m => m.MainComponent),
      }
    ]
  },
  {
    data: {
      groups: [APOLLO_USER_GROUPS.FAMILY],
    },
    canActivate: [AuthGuard, AccessGuard],
    canActivateChild: [AccessGuard],
    resolve: {
      // the Family Instruction Dialog requires the facility data to be present
      facility: facilityResolver,
      // the resident profile pages require the resident record to be present
      resident: residentResolver,
      content: contentResolver,
      queries: wizardQueryResolver,
      residentPhotos: residentPhotosResolver,
    },
    matcher: (url: UrlSegment[], segmentGroup, route) => {
      // we only allow 2 url segments
      const [root, child, notAllowed] = url;
      const group = groups[root.path as keyof typeof groups];
      if (!group) {
        return null;
      }
      const childSteps = group.steps;
      if (!childSteps) {
        return null;
      }
      const childPaths = childSteps.map(step => step.stepName);
      route.data = route.data || {};

      if (notAllowed) {
        return null;
      } else if (child) {
        if (!childPaths.includes(child.path)) {
          return null;
        }
        loadStep(group, route, child);
        return { consumed: url };
      } else if (root) {
        loadStep(group, route, child);
        return { consumed: [...url, new UrlSegment(childPaths[0], {})] };
      } else {
        //fallback
        return null;
      }
    },
    loadComponent: () => import('./shared/components/wizard-layout/wizard-layout.component').then(m => m.WizardLayoutComponent),
    children: [
      {
        path: '',
        loadComponent: () => import('./modules/wizard/containers/form/form.component').then(m => m.WizardFormComponent),
      }
    ]
  },
  { path: '**', redirectTo: 'page-not-found' }
]

function loadStep(group: WizardGroup, route: Route, child: UrlSegment) {
  const { groupName, persistenceId } = group;
  const totalSteps = group.steps.length;
  const currentStep = child ?
    group.steps.findIndex(step => step.stepName === child.path) : 0
  const config = group.steps[currentStep];
  const sidebarContent = config.sidebar.content ? [config.sidebar.content] : [];
  const formContent = config.form.content ? Object.entries(config.form.content).map(val => val[1]) : [];
  route.data = {
    ...route.data,
    config,
    persistenceId,
    progress: {
      totalSteps,
      currentStep: currentStep + 1,
      groupName,
    },
    loadContent: [...sidebarContent, ...formContent],
  };
}
